<template>
    <div class='watch'>
        <div class='embed-container'>
            <iframe src='https://www.youtube.com/embed/V8aKZO8K4pQ' frameborder='0' allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Watch'
}
</script>

<style>

.watch {
    padding: 30px 0;
    max-width: 900px;
    width: 100%;
    justify-self: center;
    align-self: center;
}

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 

.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
}

</style>